import React from "react"
import {withPrefix} from "gatsby"
// import { Helmet } from "react-helmet"
// import { Link } from "gatsby"

import "../../../styles/core.css"
import "../../../styles/styles.css"
import Layout from "../../../components/Layout"
import MenuOne from "../../../components/MenuOne"
import MenuTwo from "../../../components/MenuTwo"
import MenuThree from "../../../components/MenuThree"
import Content from "../../../components/Content"
import Gallery from "../../../components/Gallery"

const galImgs = ['/page-images/reduced.jpg']

export default function OurWork() {
  return (
    <Layout breadcrumbs={["Industrial"]}>
      <Gallery items={
      galImgs.map(image => ({
          original: withPrefix(image),
          thumbnail: withPrefix(image),
        })
      )}
    />

<Content />

      <div class="region region-content-bottom">
        <MenuOne active='our-work' />
        <MenuTwo
          active=""
          items={[
            '/our-work/residential',
            '/our-work/commercial',
            '/our-work/industrial',
            '/our-work/leisure',
            '/our-work/community',
            '/our-work/retail',
          ]} />
        <MenuThree
          items={[
            '/our-work/industrial/ashvale-engineering-downpatrick',
          ]} />
      </div>

  </Layout>

  )
}
